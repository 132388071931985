<template>
  <div>
    <div class="bg-mask"></div>
    <div class="relative m-auto h-full z-10 mx-4" >
      <div class="filter">
        <div class="filter__item" @click="findRealEstate" >
          <span class="bongah-filter">
<svg xmlns="http://www.w3.org/2000/svg" width="29.588" height="19.353" viewBox="0 0 29.588 19.353">
  <g id="Group_12961" data-name="Group 12961" transform="translate(-33.006 -164.331)">
    <path id="ic_location_city_24px" d="M15,11V5L12,2,9,5V7l.147,14H21V11Zm-2,8H11V17h2Zm0-4H11V13h2Zm0-4H11V9h2Zm0-4H11V5h2Zm6,12H17V17h2Zm0-4H17V13h2Z" transform="translate(24.006 162.331)" fill="#fff"/>
    <path id="Union_2" data-name="Union 2" d="M10.686,10.719l3.147,3.156ZM0,6.188a6.168,6.168,0,1,1,6.168,6.188A6.178,6.178,0,0,1,0,6.188Z" transform="translate(48.407 169.455)" fill="none" stroke="#fff" stroke-width="1"/>
  </g>
</svg>
          </span>
          <p>برای جستجوی بنگاه خود انتخاب کنید</p>
        </div>
        <div class="filter__item" @click="()=> this.$router.push({name : 'NotificationsRealEstate'})">
           <span class="bongah-filter">
          <svg id="Group_12763" data-name="Group 12763" xmlns="http://www.w3.org/2000/svg" width="20.002" height="25.256" viewBox="0 0 20.002 25.256">
  <path id="ic_notifications_24px" d="M21.5,19.377V13.126c0-3.838-2.05-7.051-5.626-7.9v-.85a1.875,1.875,0,0,0-3.75,0v.85c-3.588.85-5.626,4.05-5.626,7.9v6.251L4,21.877v1.25H24v-1.25Z" transform="translate(-4 -2.5)" fill="#fff"/>
  <path id="Path_17926" data-name="Path 17926" d="M5,1.913a2.5,2.5,0,0,1-5,0C0,.534,5,.534,5,1.913Z" transform="translate(7.501 20.847)" fill="#fff"/>
</svg>
        </span>
          <span class="notification__count">{{ notifCount }}</span>
          <p>اعلانات شما</p>
        </div>
        <div class="filter__item" @click="handleFilterArea">
          <span class="filter__item--city">
<svg xmlns="http://www.w3.org/2000/svg" width="49.686" height="49.729" viewBox="0 0 49.686 49.729">
  <g id="Group_12935" data-name="Group 12935" transform="translate(-102.365 1.564)">
    <path id="Rectangle_12123" data-name="Rectangle 12123" d="M0,0H30A15,15,0,0,1,45,15V30A15,15,0,0,1,30,45H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z" transform="translate(105)" fill="#ff7400"/>
    <path id="Union_1" data-name="Union 1" d="M14.549,14.582l4.284,4.294ZM0,8.417a8.4,8.4,0,1,1,8.4,8.417A8.407,8.407,0,0,1,0,8.417Z" transform="translate(117.865 13.936)" fill="none" stroke="#fff" stroke-width="1"/>
    <path id="Union_9" data-name="Union 9" d="M14.549,14.582l4.284,4.294ZM0,8.417a8.4,8.4,0,1,1,8.4,8.417A8.407,8.407,0,0,1,0,8.417Z" transform="translate(117.865 13.936)" fill="none" stroke="#fff" stroke-width="1"/>
  </g>
</svg>
          </span>
          <p>{{searchText ? searchText : `برای جستجوی شهر خود انتخاب کنید`}}</p>
        </div>
        <div class="filter__item" @click="isFiltersOpen = true">
          <span class="bongah-filter"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <g id="options" transform="translate(20 0) rotate(90)">
    <rect id="Rectangle_524" data-name="Rectangle 524" width="20" height="20" transform="translate(0 0)" fill="#fff" opacity="0"/>
    <path id="Path_1287" data-name="Path 1287" d="M3.222,9.869V.81A.81.81,0,1,0,1.6.81V9.869a2.431,2.431,0,0,0,0,4.57V15.4a.81.81,0,1,0,1.621,0V14.44a2.431,2.431,0,0,0,0-4.57Z" transform="translate(2.848 1.958)" fill="#fff"/>
    <path id="Path_1288" data-name="Path 1288" d="M4.843,8.913A2.431,2.431,0,0,0,3.222,6.628V.81A.81.81,0,0,0,1.6.81V6.628a2.431,2.431,0,0,0,0,4.57v4.2a.81.81,0,1,0,1.621,0V11.2A2.431,2.431,0,0,0,4.843,8.913Z" transform="translate(12.571 1.958)" fill="#fff"/>
    <path id="Path_1289" data-name="Path 1289" d="M4.862,2.438A2.431,2.431,0,1,0,1.621,4.723V15.4a.81.81,0,1,0,1.621,0V4.723A2.431,2.431,0,0,0,4.862,2.438Z" transform="translate(7.69 1.951)" fill="#fff"/>
  </g>
</svg>
          </span>
          <p>
            اعمال فیلتر
            <span v-if="isFilter" @click.stop="clearFilter" class="delete-filter">حذف فیلتر</span>
          </p>

        </div>
      </div>
      <!--
            <div v-show="!handleScroll.scrollDown"  class="notification__wrapper">
              <span >
                <svg @click="()=> this.$router.push({name : 'NotificationsCustomer'})" id="Group_12763" data-name="Group 12763" xmlns="http://www.w3.org/2000/svg" width="20.63" height="26.312" viewBox="0 0 21.63 27.312">
                  <path id="ic_notifications_24px" d="M22.926,20.75V13.991c0-4.15-2.217-7.625-6.083-8.544V4.528a2.028,2.028,0,0,0-4.056,0v.919C8.907,6.366,6.7,9.827,6.7,13.991V20.75L4,23.454v1.352H25.63V23.454Z" transform="translate(-4 -2.5)" fill="#642470"/>
                  <path id="Path_17926" data-name="Path 17926" d="M5.407,2A2.7,2.7,0,0,1,0,2C0,.506,5.407.506,5.407,2Z" transform="translate(8.111 22.615)" fill="#642470"/>
                </svg>
              </span>
              <span class="notification__count">{{notifCount}}</span>
            </div>
      -->
      <!--
            <div :style="[handleScroll.scrollDown ? {  transition: '0.08s',borderRadius : '0' ,margin : '0'} : '']" class="search__wrapper">
              <span class="svg-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="19.686" height="19.729" viewBox="0 0 19.686 19.729">
                  <path id="Union_1" data-name="Union 1" d="M14.549,14.582l4.284,4.294ZM0,8.417a8.4,8.4,0,1,1,8.4,8.417A8.407,8.407,0,0,1,0,8.417Z" transform="translate(0.5 0.5)" fill="none" stroke="#ff7400" stroke-width="1"/>
                </svg>
              </span>
              <input type="submit" @click="handleFilterArea" class="text-left cursor-pointer" :value="searchText">
              <span class="square-icon" @click="isFiltersOpen = true">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                  <g id="options" transform="translate(20 0) rotate(90)">
                    <rect id="Rectangle_524" data-name="Rectangle 524" width="20" height="20" transform="translate(0 0)" fill="#fff" opacity="0"/>
                    <path id="Path_1287" data-name="Path 1287" d="M3.222,9.869V.81A.81.81,0,1,0,1.6.81V9.869a2.431,2.431,0,0,0,0,4.57V15.4a.81.81,0,1,0,1.621,0V14.44a2.431,2.431,0,0,0,0-4.57Z" transform="translate(2.848 1.958)" fill="#fff"/>
                    <path id="Path_1288" data-name="Path 1288" d="M4.843,8.913A2.431,2.431,0,0,0,3.222,6.628V.81A.81.81,0,0,0,1.6.81V6.628a2.431,2.431,0,0,0,0,4.57v4.2a.81.81,0,1,0,1.621,0V11.2A2.431,2.431,0,0,0,4.843,8.913Z" transform="translate(12.571 1.958)" fill="#fff"/>
                    <path id="Path_1289" data-name="Path 1289" d="M4.862,2.438A2.431,2.431,0,1,0,1.621,4.723V15.4a.81.81,0,1,0,1.621,0V4.723A2.431,2.431,0,0,0,4.862,2.438Z" transform="translate(7.69 1.951)" fill="#fff"/>
                  </g>
                </svg>
              </span>
            </div>
      -->
      <topFilter
          v-model="topFilter"
      />
      <section ref="homeScroll" class="product_wrapper "  @scroll="handleWheel2">
        <Estates />
      </section>
      <Filters
          v-if="isFiltersOpen"
          :isFiltersOpen="isFiltersOpen"
          :isScrollDown="handleScroll.scrollDown"
          @close-filter-box="closeFilter"
          @is-scroll="isScroll"
      />
    </div>
    <NavigationButtonRealEstate  />

  </div>

</template>

<script>

import NavigationButtonRealEstate from '../../components/navigationButton/RealEstateButton'
import axios from '../../plugins/axios'
import Filters from "../../components/estate/filters";
import Estates from "../../components/estate/Estates";
import topFilter from "../../components/estate/topFilter";
import { mapGetters } from "vuex";
export default {
  name: 'HomeRealEstate',
  data(){
    return{
      isFiltersOpen : false,
      notifCount : null,
      handleScroll :{
        scrollDown : false,
        scrollUp : false,
        offsetY : 300
      },
      lastScrollTop : 0,
      isScrollBugFix : true,
      topFilter : this.$store.state.filters.filter.advertisement_type.value,
      timer : null,
    }
  },
  components: {
    NavigationButtonRealEstate,
    Estates,
    Filters,
    topFilter
  },

  methods:{
    closeFilter(data){
      this.isFiltersOpen = false
      this.topFilter = data
    },
    handleWheel2(event){
      let lastScroll = event.target.scrollTop
      if (lastScroll > 250 && lastScroll > this.lastScrollTop){
        this.handleScroll.scrollDown = true
      }else{
        if(this.timer){
          clearTimeout(this.timer);
          this.timer = null
        }
        this.timer = setTimeout(()=>{
          this.handleScroll.scrollDown = false
        },200)

      }
      this.lastScrollTop = lastScroll
    },
    async clearFilter(){
      this.$store.commit('filters/reset')

      await this.$store.commit('filters/CLEAR_ESTATES')
      await this.$store.dispatch('filters/getDataWithFilter')
    },
    handleFilterArea(){
      this.$router.push({name : 'RealEstateSelectArea'})
    },
    isScroll(data){
      this.isScrollBugFix = data
    },
    findRealEstate(){
      if (this.$route.meta.isRealEstate){
        this.$router.push({name : 'FindRealEstateRealEstate'})
      }else if (this.$route.meta.isCustomer){
        this.$router.push({name : 'FindRealEstateCustomer'})
      }
    }
  },
  computed :{
    searchText(){
      let city = JSON.parse(localStorage.getItem('city'))
      if ('city' in localStorage){
        let areas = this.$store.state.filters.filter.area.value;
        let areasFilter = areas.toString();
        return `${city?.name} ${areasFilter !== '' ? ' در منطقه ' + areasFilter  : ''} `
      }
      return 'شهر خود را انتخاب کنید'
    },
    ...mapGetters({
      page : 'filters/page'
    }),
    isFilter(){
      return this.$store.state.filters.isFilterEstate
    }


  },
  mounted() {
    axios.get('/users').then(({data})=>{
      localStorage.setItem('user', JSON.stringify(data.data))
    })
    // when scroll end of list
    var pages = this.page
    var repeatPage = null
    const listElm = this.$refs.homeScroll;
    listElm.addEventListener('scroll', e => {
      if (listElm.scrollTop > 300) {
        pages = this.page
        this.isScrollBugFix = true
      }
      if (this.isScrollBugFix){
        if (listElm.scrollTop + listElm.clientHeight + 5 > listElm.scrollHeight) {
          pages = this.$store.state.filters.nextPage
          if (pages <= this.$store.state.filters.lastPage && repeatPage !== pages) {
            this.$store.commit('filters/SET_PAGE', pages)
            this.$store.dispatch('filters/getDataWithFilter')
            repeatPage = pages
          }
        }

      }
    });
    // get notifCount
    let user = JSON.parse(localStorage.getItem('user'))
    this.notifCount = user.notifications_count
  },
}
</script>
<style>

</style>